<template>
  <div class="container">
    <login-form @setEmployee="setEmployee" v-if="!employee.email"></login-form>
    <div v-if="employee.email">
      <p>Logged In as {{ employee.email }}</p>
    </div>
    <Stats :domain="domain" :email="employee.email" v-if="employee.email" />
  </div>
</template>

<script>
import Stats from "@/components/Stats.vue";
import LoginForm from "@/components/LoginForm.vue";
export default {
  name: "Statistics",
  components: {
    Stats,
    LoginForm,
  },
  data() {
    return {
      records: [],
      employee: {
        email: "",
        firstdose: false,
        seconddose: false,
        notdue: false,
      },
    };
  },
  computed: {
    domain() {
      return this.employee.email.split("@")[1];
    },
  },
  methods: {
    setEmployee(e) {
      this.employee = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.statsitem {
  height: 100px;
  width: 150px;
  border: 2px solid #35517b;
  margin: 1rem 2rem;
  color: #35517b;
  padding: 2rem;
  border-radius: 0.3rem;
  box-shadow: 5px 10px lightgrey;
}
.statshead {
  text-transform: uppercase;
  margin: 0;
}
.statsdetail {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}
.percent {
  margin: 0;
}
</style>
